import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/Layout";
import SEO from "../../components/seo";
import IntroCopy from "../../components/IntroCopy";
import FullWidthInfo from "../../components/FullWidthInfo";
import ImageGallery from "../../components/ImageGallery";
import ImageLockup from "../../components/ImageLockup";

const ChimneyServicesPage = ({ data }) => (
  <Layout>
    <main>
      <SEO title="Portfolio - Chimney Services" />
      <IntroCopy
        headline="Chimney Services"
        body="Our Tradesmen can provide a wide range of Chimney Services including, Rebuilding, Repointing, Flaunching, Chimney Pot & Cowl installation, Flue Liners, General Restoration and removal."
        topPadding
      />
      <ImageGallery
        images={data.images.nodes.filter(image => image.name === "1")}
      />
      <FullWidthInfo
        headline="Chimney Services"
        body="Chimneys can sometimes be a problematic area of a roof. By nature the chimney is very exposed to the elements and can require regular attention. Our staff are experienced in the nature of the problems and are best suited to solve them."
      />
      <ImageLockup
        left={data.images.nodes.filter(image => image.name === "2")}
        right={data.images.nodes.filter(image => image.name === "1")}
      />
      <FullWidthInfo
        headline="Chimney Restoration"
        body="Here we were called to a chimney that needed an overhaul. Using a combination of coloured mortar and ordinary mortar, the chimney was restored to its former glory."
      />
      <ImageLockup
        left={data.images.nodes.filter(image => image.name === "3")}
        right={data.images.nodes.filter(image => image.name === "4")}
        withLabels
      />
    </main>
  </Layout>
);

export const query = graphql`
  query ChimneyServicesImages {
    images: allFile(
      filter: { relativeDirectory: { eq: "portfolio/chimney-services" } }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default ChimneyServicesPage;
